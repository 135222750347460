<template>
    <div class="flex flex-1 flex-col">
        <ConfirmationModal codeConfirmation @success="getAll" />

        <ModalEditHistory
            :visible="modalEditHistoryVisible"
            :selectedHistoryId="selectedHistoryId"
            @refresh="getAll"
            @close="modalEditHistoryVisible = false"
        />

        <ModalAddHistory
            :visible="modalAddHistoryVisible"
            @refresh="getAll"
            @close="modalAddHistoryVisible = false"
        />

        <HistorySummary :historyData="historyData" />

        <div class="card flex flex-col" style="min-height: calc(100vh - 205px)">
            <div class="flex justify-end pb-4 mb-4 border-b">
                <div class="menu flex">
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.add_entry')" placement="top">
                        <el-button type="primary" size="mini" :disabled="!userCan('create service history') || $wait.is('loading.initial_*')" @click="showAddHistoryModal">
                            <fa-icon :icon="['far', 'plus']" fixed-width />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.export_to_pdf')" placement="top">
                        <el-button type="primary" size="mini" :disabled="historyData.length == 0" @click="toPdf(false)">
                            <fa-icon :icon="['fas', 'file-pdf']" fixed-width />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.print')" placement="top">
                        <el-button type="primary" size="mini" :disabled="historyData.length == 0" class="btn-print mr-2" @click="toPdf(true)">
                            <fa-icon :icon="['fas', 'print']" fixed-width />
                        </el-button>
                    </el-tooltip>
                    <DateRangePicker :subtractedMonths="12" @change="getAll" />
                </div>
            </div>

            <Table :tableData="historyData" @handleEdit="handleEdit" @handleDelete="handleDelete" />

            <InitialLoader v-if="$wait.is('loading.initial_*')" />

            <NoDataInformation :data="noDataInformation" waitKey="loading" />
        </div>
    </div>
</template>
<script>
import queryString from 'query-string';
import Api from './history.api';

export default {
    components: {
        DateRangePicker:  () => import(/* webpackChunkName: "DateRangePicker" */ '@/components/DateRangePicker'),
        Table:            () => import(/* webpackChunkName: "Client/HistoryTable" */ './HistoryTable'),
        HistorySummary:   () => import(/* webpackChunkName: "Client/HistorySummary" */ './HistorySummary'),
        ModalEditHistory: () => import(/* webpackChunkName: "Client/HistoryModalEdit" */ './HistoryModalEdit'),
        ModalAddHistory:  () => import(/* webpackChunkName: "Client/HistoryModalAdd" */ './HistoryModalAdd'),
    },

    props: {
        clientId: {
            type:    Number,
            default: null,
        },
    },

    data() {
        return {
            noDataInformation:       false,
            historyData:             [],
            modalEditHistoryVisible: false,
            modalAddHistoryVisible:  false,
            selectedHistoryId:       null,
        };
    },

    beforeCreate() {
        this.$wait.start('loading.initial_history');
    },

    created() {
        this.getAll();
    },

    methods: {
        async getAll() {
            this.$wait.start('loading');
            try {
                const query = queryString.parse(window.location.search);
                const from = query.from ? query.from : this.$dayjs().subtract(12, 'months').startOf('month').unix();
                const to = query.to ? query.to : this.$dayjs().endOf('month').unix();
                const data = await Api.getAll(this.clientId, from, to);
                this.historyData = data;
                this.noDataInformation = data.length;
            } finally {
                this.$wait.end('loading');
                this.$wait.end('loading_date_range.prev');
                this.$wait.end('loading_date_range.next');
                this.$wait.end('loading.initial_history');
            }
        },

        async toPdf(toPrint = false) {
            const query = queryString.parse(window.location.search);

            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/services/service_history/service_history_pdf_sign_route`, {
                params: {
                    client: this.clientId,
                    from:   query.from ? query.from : this.$dayjs().subtract(12, 'months').startOf('month').unix(),
                    to:     query.to ? query.to : this.$dayjs().endOf('month').unix(),
                    print:  toPrint,
                },
            });
            window.open(data);
        },

        handleEdit(id) {
            this.selectedHistoryId = id;
            this.modalEditHistoryVisible = true;
        },

        showAddHistoryModal() {
            this.modalAddHistoryVisible = true;
        },

        handleDelete(id) {
            this.$store.commit('showConfirmationModal', {
                actionUrl:  Api.destroyURL(id),
                actionType: 'destroy',
            });
        },
    },
};
</script>
<style scoped>
.menu .el-button--mini {
    padding: 7px !important;
}
</style>
