export default {
    async getAll(clientId, from, to) {
        try {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/history/client/${clientId}`, {
                params: {
                    from,
                    to,
                },
            });
            return data;
        } catch (error) {
            console.log('🚀 ~ file: history.api.js ~ getAll ~ error', error);
            throw error;
        }
    },

    async getDetails(id) {
        try {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/history/${id}`);
            return data;
        } catch (error) {
            console.log('🚀 ~ file: history.api.js ~ getDetails ~ error', error);
            throw error;
        }
    },

    async update(id, historyData) {
        try {
            await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/clients/history/${id}`, {
                hours:   historyData.hours,
                minutes: historyData.minutes,
            });
        } catch (error) {
            console.log('🚀 ~ file: history.api.js ~ update ~ error', error);
            throw error;
        }
    },

    destroyURL(id) {
        return `${process.env.VUE_APP_CORE_API_URL}/clients/history/${id}`;
    },
};
